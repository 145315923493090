import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-100" }
const _hoisted_2 = {
  key: 0,
  class: "loading-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_RoadMapAssessmentDetails = _resolveComponent("RoadMapAssessmentDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_progress_circular, {
            color: "primary",
            indeterminate: "",
            size: 80
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Loading")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.assessmentModel.Id != null && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_RoadMapAssessmentDetails, {
          key: 1,
          assessmentModel: _ctx.assessmentModel,
          roadMapId: _ctx.roadMapId,
          onNextStep: _ctx.ReturnToRoadMap
        }, null, 8, ["assessmentModel", "roadMapId", "onNextStep"]))
      : _createCommentVNode("", true)
  ]))
}