import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "editor-container" }
const _hoisted_2 = { class: "editor-header" }
const _hoisted_3 = { class: "editor-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.mode.toUpperCase()), 1),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("textarea", {
        ref: "editor",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editorContent) = $event))
      }, null, 512), [
        [_vModelText, _ctx.editorContent]
      ])
    ])
  ]))
}