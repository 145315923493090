import NotFound from "shared-components/src/components/NotFound.vue";
import Login from "@/pages/Login.vue";
import LoginWithToken from "@/pages/LoginWithToken.vue";
import Logout from "@/pages/Logout.vue";
import Information from "@/pages/teamMember/Information.vue";
import Signup from "@/pages/Signup.vue";
import EmailVerification from "@/pages/EmailVerification.vue";
import VerifyEmail from "@/pages/VerifyEmail.vue";
import TimesheetList from "@/pages/timeSheet/Timesheet.vue";
import NotificationList from "@/components/User/NotificationList.vue";
import RepositoryList from "@/pages/repositories/Repository.vue";
import CommitmentList from "@/pages/commitment/CommitmentList.vue";
import ResetPassword from "@/pages/ResetPassword.vue";
import CareerHistory from "shared-components/src/components/Profile/careerHistory/CareerHistory.vue";
import PayRunList from "@/pages/payRun/PayRunList.vue";
import AccessList from "@/pages/access/Access.vue";
import TimesheetReport from "@/pages/reports/TimesheetReport.vue";
import MainLayout from "@/pages/layouts/MainLayout.vue";
import RegisterLayout from "@/pages/layouts/RegisterLayout.vue";
import SignupSecondaryTemplate from "@/components/register/SignupSecondaryTemplate.vue";
import HRCommitmentsReport from "@/pages/reports/HRCommitmentsReport.vue";
import WeeklyTimesheetAudit from "@/pages/weeklyTimesheetAudit/weeklyTimesheetAudit.vue";
import AuthorList from "@/pages/author/AuthorList.vue";
import Dashboard from "@/pages/dashboard/Dashboard.vue";
import PaymentMethods from "@/pages/paymentMethods/PaymentMethods.vue";
import CompleteProfile from "@/pages/CompleteProfile.vue";
import LoanList from "@/pages/loan/LoanList.vue";
import { RouteRecordRaw } from "vue-router";
import Roles from "./roles";
import ExtraValidations from "./extraValidations";
import MyTrainingRoadmaps from "../components/training/MyTrainingRoadmaps/MyTrainingRoadmaps.vue";
import TrainingList from "../components/training/MyTrainingRoadmaps/TrainingList.vue";
import RoadMapCourseDetail from "../components/training/MyTrainingRoadmaps/CourseDetail.vue";
import LessonsMenu from "../components/training/MyTrainingRoadmaps/LessonsMenu.vue";
import CoursesList from "../components/training/MyCourses/CoursesList.vue";
import CourseDetail from "../components/training/MyCourses/CourseDetail.vue";
import AssessmentList from "@/components/training/MyAssessments/AssessmentList.vue";
import AssessmentDetail from "@/components/training/MyAssessments/AssessmentDetail.vue";
import RoadMapAssessmentDetail from "@/components/training/MyTrainingRoadmaps/AssessmentDetail.vue";
export const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/team-member",
  },
  {
    path: "/payRuns",
    redirect: "/team-member/pay-runs",
  },
  {
    path: "/teamMember/dashboard",
    redirect: "/team-member/dashboard",
  },
  {
    path: "/teamMember/completeProfile",
    redirect: "/team-member/completeProfile",
  },
  {
    path: "/teamMember/profile",
    redirect: "/team-member/profile",
  },

  {
    path: "/careerHistories",
    redirect: "/team-member/career-histories",
  },
  {
    path: "/loans",
    redirect: "/team-member/loans",
  },
  {
    path: "/paymentMethods",
    redirect: "/team-member/paymentMethods",
  },
  {
    path: "/repositories",
    redirect: "/team-member/repositories",
  },
  {
    path: "/completeProfile",
    redirect: "/team-member/complete-profile",
  },
  {
    path: "/emailVerification",
    redirect: "/team-member/email-verification",
  },
  {
    path: "/timesheet/list/:dateOffset?",
    redirect: "/team-member/timesheet/:dateOffset?",
  },
  {
    path: "/timesheet/list/edit/:id",
    redirect: "/team-member/timesheet/edit/:id",
  },
  {
    path: "/timesheet/list/register/:date?",
    redirect: "/team-member/timesheet/register/:date?",
  },
  {
    path: "/login",
    redirect: "/register/login",
  },
  {
    path: "/verifyEmail",
    redirect: "/register/verify-email",
  },
  {
    path: "/login-with-token",
    name: "login-with-token",
    component: LoginWithToken,
  },
  {
    path: "/logout",
    redirect: "/register/logout",
  },
  {
    path: "/signup",
    redirect: "/register/signup",
  },
  {
    path: "/resetPassword",
    redirect: "/register/reset-password",
  },
  {
    path: "/weeklyTimesheetAudit",
    redirect: "team-member/weekly-timesheet-audit",
  },
  {
    path: "/authors",
    redirect: "team-member/authors",
  },
  {
    path: "/commitments",
    redirect: "team-member/commitments",
  },
  // {
  //   path: "/dashboard",
  //   redirect: "team-member/dashboard",
  // },
  {
    path: "/team-member/training/roadmaps/:roadMapId/courseDetail/:courseId/start",
    name: "LessonsMenu",
    component: LessonsMenu,
    props: true,
  },
  {
    path: "/team-member",
    name: "teamMember",
    component: MainLayout,
    meta: {
      authorize: true,
    },
    children: [
      {
        path: "",
        redirect: { name: "teamMemberDashboard" },
      },
      {
        path: "dashboard",
        name: "teamMemberDashboard",
        meta: {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: Dashboard,
      },
      {
        path: "training/roadmaps",
        name: "myRoadMaps",
        meta: {
          title: "Roadmap List",
          icon: "mdi-account-school",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: TrainingList,
      },
      {
        path: "training/courses",
        name: "courses",
        meta: {
          title: "Courses List",
          icon: "mdi-book-outline",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: CoursesList,
        props: {
          backButtonVisibility: false,
          addToCoursesButtonVisibility: false,
          showAsPage: true,
          topValue: "69px",
          heightValue: "545px",
          paddingValue: "35px",
        },
      },
      {
        path: "training/courses/:courseId",
        name: "CourseDetail",
        meta: {
          title: "Course Detail",
          icon: "mdi-account-school",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: CourseDetail,
        props: true,
      },
      {
        path: "training/roadmaps/:roadMapId",
        name: "myRoadMapDetail",
        meta: {
          title: "My Training Roadmaps",
          icon: "mdi-account-school",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: MyTrainingRoadmaps,
        props: true,
      },
      {
        path: "training/assessments",
        name: "Assessments",
        meta: {
          title: "Assessments",
          icon: "mdi-school-outline",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: AssessmentList,
      },
      {
        path: "training/assessments/:assessmentId",
        name: "AssessmentDetail",
        meta: {
          title: "Assessment Detail",
          icon: "mdi-school-outline",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: AssessmentDetail,
        props: true,
      },
      {
        path: "training/roadmaps/:roadMapId/courses/:courseId",
        name: "RoadMapCourseDetail",
        component: RoadMapCourseDetail,
        props: true,
      },
      {
        path: "training/roadmaps/:roadMapId/assessments/:assessmentId",
        name: "RoadMapAssessmentDetail",
        component: RoadMapAssessmentDetail,
        props: true,
      },
      {
        path: "completeProfile",
        name: "completeProfile",
        meta: {
          title: "Complete Profile",
          icon: "mdi-account",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: CompleteProfile,
      },
      {
        path: "profile",
        name: "teamMemberProfile",
        meta: {
          title: "Profile",
          icon: "mdi-account",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: Information,
        props: true,
      },
      {
        path: "pay-runs",
        name: "PayRuns",
        meta: {
          title: "Pay Runs",
          icon: "mdi-account-cash",
          extraRequiredValidation: [ExtraValidations.ActiveCommitments],
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: PayRunList,
      },
      {
        path: "career-histories",
        name: "careerHistories",
        meta: {
          title: "Career Histories",
          icon: "mdi-table-chair",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: CareerHistory,
      },
      {
        path: "loans",
        name: "loans",
        meta: {
          title: "Loans",
          icon: "mdi-cash-multiple",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: LoanList,
      },
      {
        path: "paymentMethods",
        name: "paymentMethods",
        meta: {
          title: "Payment Methods",
          icon: "mdi-cash-refund",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: PaymentMethods,
      },
      {
        path: "repositories",
        name: "repositories",
        meta: {
          title: "Repositories",
          icon: "mdi-source-repository",
          frontAuthRequired: true,
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: RepositoryList,
      },
      {
        path: "commitments",
        name: "commitments",
        meta: {
          title: "Commitments",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: CommitmentList,
      },
      {
        path: "email-verification",
        name: "emailVerification",
        meta: {
          title: "Email verification",
          frontAuthRequired: true,
        },
        component: EmailVerification,
      },
      {
        path: "weekly-timesheet-audit",
        name: "weeklyTimesheetAudit",
        meta: {
          title: "Weekly Timesheet Audit",
          icon: "mdi-history",
          roles: [Roles.Linemanager],
        },
        component: WeeklyTimesheetAudit,
      },

      {
        path: "authors",
        name: "authors",
        meta: {
          title: "Authors",
          icon: "mdi-history",
          roles: [Roles.Linemanager],
        },
        component: AuthorList,
      },
      {
        path: "timesheet/:dateOffset?",
        name: "listTimesheet",
        meta: {
          title: "Timesheet list",
          icon: "mdi-history",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: TimesheetList,
      },
      {
        path: "reports/timesheet-report",
        name: "timesheetReport",
        meta: {
          title: "Timesheet Report",
          icon: "mdi-server-security",
          roles: [Roles.Linemanager],
        },
        component: TimesheetReport,
      },
      {
        path: "access/list",
        name: "accessList",
        meta: {
          title: "Access list",
          icon: "mdi-server-security",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: AccessList,
      },
      {
        path: "/notifications",
        name: "notificationList",
        meta: {
          title: "Notification list",
          icon: "mdi-server-security",
          roles: [Roles.Linemanager, Roles.Teammember],
        },
        component: NotificationList,
      },
    ],
  },
  {
    path: "/register",
    name: "register",
    component: RegisterLayout,
    meta: {
      authorize: false,
    },
    children: [
      {
        path: "",
        redirect: "login",
      },
      {
        path: "login",
        name: "login",
        meta: {
          title: "Log In",
          description: "Enter your username and password to log in",
        },
        component: Login,
      },
      {
        path: "logout",
        name: "logout",
        meta: {
          title: "Logout",
          // authorize: false,
          // roles: ["Admin", "TeamMember", "Customer"]
        },
        component: Logout,
      },
      {
        path: "signup",
        name: "Sign Up",
        meta: {
          title: "Signup",
          description: "Enter the required information for registration",
          template: SignupSecondaryTemplate,
        },
        component: Signup,
      },
      {
        path: "reset-password:token?",
        name: "reset Password",
        meta: {
          title: "Reset password",
          description: "Reset your password",
        },
        component: ResetPassword,
      },
      {
        path: "verify-email:token?",
        name: "verifyEmail",
        meta: {
          title: "Verify Email",
          description: "Verify Email",
        },
        component: VerifyEmail,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    meta: {
      title: "404 Not Found",
      roles: [Roles.Admin, Roles.Customer, Roles.Linemanager, Roles.Teammember],
    },
    component: NotFound,
  },
];
