<template>
  <div class="h-100">
    <div class="loading-container" v-if="isLoading">
      <v-progress-circular color="primary" indeterminate :size="80"> Loading</v-progress-circular>
    </div>
    <RoadMapAssessmentDetails
      v-if="assessmentModel.Id != null && !isLoading"
      :assessmentModel="assessmentModel"
      :roadMapId="roadMapId"
      @nextStep="ReturnToRoadMap"
    ></RoadMapAssessmentDetails>
  </div>
</template>

<script lang="ts">
import RoadMapService from "@/services/RoadMapService";
import AssessmentModel from "shared-components/src/models/AssessmentModel";
import RoadMapAssessmentDetails from "./RoadMapAssessmentDetails.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    RoadMapAssessmentDetails,
  },
  props: {
    roadMapId: {
      type: String,
      required: true,
    },
    assessmentId: {
      type: String,
      required: true,
    },
  },
  async mounted() {
    await this.loadAssessmentModels();
  },
  data() {
    return {
      assessmentModel: {} as AssessmentModel,
      showErrorAlert: false,
      isLoading: false,
    };
  },
  methods: {
    async loadAssessmentModels() {
      if (this.roadMapId && this.assessmentId) {
        this.isLoading = true;
        this.assessmentModel = await RoadMapService.GetTeammemberRoadMapAssessmentDetails(this.roadMapId, this.assessmentId);
        this.isLoading = false;
      }
    },
    ReturnToRoadMap() {
      this.$router.push({
        name: "myRoadMapDetail",
        params: { roadMapId: this.roadMapId },
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/style/font.scss";
@import "node_modules/shared-components/assets/colors.scss";

.course-container {
  padding: 20px;

  .course-image {
    border-radius: 5px;
  }

  .details-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .flex-section {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .favorite-btn {
    position: absolute;
    right: 5px;
  }

  .heart_icon {
    color: $racecar;
  }

  .start-button {
    background-color: $racecar;
    left: 0px;
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .single-line-content {
    max-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .detail-lesson {
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #242424 !important;
    border: 1px solid #242424 !important;
  }
}
</style>
