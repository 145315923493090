import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextField = _resolveComponent("TextField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!

  return (_openBlock(), _createBlock(_component_v_form, {
    ref: "frmLogin",
    id: _ctx.formId,
    onSubmit: _withModifiers(_ctx.submit, ["prevent"]),
    class: "form-inputs"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                outlined: "",
                modelValue: _ctx.model.userName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.userName) = $event)),
                "prepend-icon": "mdi-account",
                label: "User Name",
                placeholder: "User Name",
                type: "text",
                class: "login-field",
                rules: [_ctx.validations.required]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_TextField, {
                outlined: "",
                modelValue: _ctx.model.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.password) = $event)),
                "prepend-icon": "mdi-key",
                label: "Password",
                placeholder: "Password",
                type: "password",
                class: "login-field",
                rules: [_ctx.validations.required]
              }, null, 8, ["modelValue", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_col, { class: "more-options text-right pt-0 pb-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_router_link, { to: "/register/reset-password" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Reset password")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["id", "onSubmit"]))
}