import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notification_list = _resolveComponent("notification-list", true)!

  return (_ctx.userInfo.id)
    ? (_openBlock(), _createBlock(_component_notification_list, {
        key: 0,
        userId: _ctx.userInfo.id,
        isClient: false
      }, null, 8, ["userId"]))
    : _createCommentVNode("", true)
}