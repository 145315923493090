<template>
  <div class="code-editor-container">
    <div class="code-editor-header">
      <div class="code-editor-header-question">
        {{ Question?.Question }}
      </div>
      <div class="code-editor-header-action">
        <v-btn @click="execute" v-if="showExecute">Execute</v-btn>
        <v-btn @click="submit">Submit</v-btn>
      </div>
    </div>

    <MultiplePanelVertical class="custom-resizer" layout="vertical">
      <div class="pane editors-panel" :style="{ height: showExecute == true ? 'unset' : '100%' }">
        <MultiplePanelHorizontal class="custom-code-resizer" layout="horizontal">
          <template v-for="(item, index) in editorValues">
            <div class="pane" :style="{ width: 100 / editorValues.length + '%' }">
              <div class="editor">
                <CodeEditor :mode="item.mode" v-model="item.value"></CodeEditor>
              </div>
            </div>
            <MultiplePanelResizer layout="h"></MultiplePanelResizer>
          </template>
        </MultiplePanelHorizontal>
      </div>
      <MultiplePanelResizer layout="v" v-if="showExecute"></MultiplePanelResizer>
      <div class="pane output-panel" v-if="showExecute">
        <div ref="output" class="editor-output"></div>
      </div>
    </MultiplePanelVertical>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CodeEditor from "../../codeEditor/CodeEditor.vue";
import MultiplePanelVertical from "../../multiplePanel/MultiplePanelVertical.vue";
import MultiplePanelHorizontal from "../../multiplePanel/MultiplePanelHorizontal.vue";
import MultiplePanelResizer from "../../multiplePanel/MultiplePanelResizer.vue";
import { Question } from "shared-components/src/models/AssessmentModel";
import RoadMapService from "@/services/RoadMapService";
import { EditorMapper } from "shared-components/src/definitions/constants";

interface EditorValue {
  mode: string;
  value: string;
}

export default defineComponent({
  props: {
    Question: {
      type: Object as () => Question,
    },
    assessmentId: {
      type: String,
      required: true,
    },
    courseId: {
      type: String,
    },
    roadMapId: {
      type: String,
    },
  },
  components: {
    MultiplePanelHorizontal,
    MultiplePanelVertical,
    MultiplePanelResizer,
    CodeEditor,
  },
  data() {
    return {
      isResizerYActive: false,
      editorValues: [] as EditorValue[],
    };
  },
  mounted() {
    let talentAnswer: null | any = null;
    if (this.Question?.TalentAnswer) {
      talentAnswer = JSON.parse(this.Question.TalentAnswer);
    }
    this.Question?.Editors.forEach((item) => {
      this.editorValues.push({
        mode: (EditorMapper as any)[item.Value.toLowerCase()],
        value: talentAnswer != null ? talentAnswer[(EditorMapper as any)[item.Value.toLowerCase()]] : "",
      } as EditorValue);
    });
  },
  methods: {
    execute() {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("height", "100%");
      iframe.setAttribute("width", "100%");
      const htmlContent = this.editorValues.find((item) => item.mode == "htmlmixed");
      const cssContent = this.editorValues.find((item) => item.mode == "css");
      const jsContent = this.editorValues.find((item) => item.mode == "javascript");
      iframe.src = this.getGeneratedPageURL(htmlContent?.value, cssContent?.value, jsContent?.value);
      (this.$refs.output as any).innerHTML = "";
      (this.$refs.output as any).appendChild(iframe);
    },
    async submit() {
      let answer = {};
      for (const editor of this.editorValues) {
        (answer as any)[editor.mode] = editor.value;
      }
      const response = await RoadMapService.SubmitAssessmentQuestionAnswer(
        JSON.stringify(answer),
        this.assessmentId,
        this.courseId,
        this.Question?.Id,
        this.roadMapId
      );
      if (response == true) {
        this.$emit("Submitted", JSON.stringify(answer));
      }
    },
    getGeneratedPageURL(html: any, css: any, js: any) {
      const getBlobURL = (code: any, type: any) => {
        const blob = new Blob([code], { type });
        return URL.createObjectURL(blob);
      };

      let cssURL = "";
      let jsURL = "";
      if (css) {
        cssURL = getBlobURL(css, "text/css");
      }
      if (js) {
        jsURL = getBlobURL(js, "text/javascript");
      }

      const source = `
        <html>
          <head>
            ${css ? `<link rel="stylesheet" type="text/css" href="${cssURL}" />` : ""}
            ${js ? `<script src="${jsURL}"><\/script>` : ""}
          </head>
          <body>
            ${html || ""}
          </body>
        </html>
      `;

      return getBlobURL(source, "text/html");
    },
  },
  computed: {
    showExecute() {
      if (
        this.Question?.Editors.some((item) => item.Value.toLowerCase() == "html") &&
        this.Question?.Editors.every((item) => ["html", "css", "javascript"].includes(item.Value.toLowerCase()))
      ) {
        return true;
      }
      return false;
    },
  },
});
</script>
<style lang="scss">
.code-editor-container {
  width: 100%;
  height: 100%;
  background-color: #eee;

  .code-editor-header {
    display: flex;
    padding: 10px;
    align-items: center;
    .code-editor-header-question {
      color: #000;
      flex: 1;
    }

    .code-editor-header-action {
      display: flex;
      gap: 10px;
    }
  }

  .custom-resizer {
    width: 100%;
    height: 100%;
    .editors-panel {
      min-height: 150px;
    }
    .output-panel {
      flex-grow: 1;
      padding: 0 !important;
      .editor-output {
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
    .pane {
      text-align: left;
      overflow: hidden;
      background: #eee;
      border: 1px solid #ccc;
    }
    .multipane-resizer-v {
      margin: 0;
      top: 0;
      left: 0;
      position: relative;
      background-color: #999;

      &:before {
        display: block;
        content: "";
        width: 40px;
        height: 5px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -2px;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }

      &:hover {
        &:before {
          border-color: #d7d7d7;
        }
      }
    }
  }

  .custom-code-resizer {
    width: 100%;
    height: 100%;
    .pane {
      text-align: left;
      padding: 0;
      overflow: hidden;
      background: #eee;
      border: 1px solid #ccc;
      height: 100%;
      position: relative;
      .editor {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
    .multipane-resizer-h {
      margin: 0;
      left: 0;
      position: relative;
      background-color: #999;

      &:before {
        display: block;
        content: "";
        width: 3px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -1.5px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }

      &:hover {
        &:before {
          border-color: #d7d7d7;
        }
      }
    }
  }
}
</style>
