import { createApp } from "vue";
import "@/extensions/prototype";
import "@mdi/font/css/materialdesignicons.css";
import router from "./router/index";
import store from "./store/index";
import App from "./App.vue";
import "vuetify/styles";
import { createVuetify, ThemeDefinition } from "vuetify";
import { mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@/assets/style/app.scss";
import { AppInsightsPlugin, AppInsightsPluginOptions } from "vue3-application-insights";
import { instrumentationKey } from "shared-components/src/plugins/applicationInsight";
import "shared-components/assets/main.scss";
import "shared-components/assets/style/app.scss";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import TextAreaField from "shared-components/src/components/Fields/TextAreaField.vue";
import SelectField from "shared-components/src/components/Fields/SelectField.vue";
import FileUploadField from "shared-components/src/components/Fields/FileUploadField.vue";
import ComboBoxField from "shared-components/src/components/Fields/ComboBoxField.vue";
import AutoCompleteField from "shared-components/src/components/Fields/AutoCompleteField.vue";
import PasswordField from "shared-components/src/components/Fields/PasswordField.vue";
import CurrencyTextField from "shared-components/src/components/Fields/CurrencyTextField.vue";
import { messaging, firebaseVapidKey } from "shared-components/src/plugins/firebase";
import { VCalendar } from "vuetify/labs/VCalendar";
import { VDateInput } from "vuetify/labs/VDateInput";
import UserService from "./services/UserService";

localStorage.removeItem("username");
localStorage.removeItem("password");
const dark: ThemeDefinition = {
  dark: true,
  colors: {
    primary: "#fff",
    secondary: "#302c2b",
    accent: "#dc1e28",
    background: "#121212",
  },
};

const vuetify = createVuetify({
  icons: {
    defaultSet: "mdi",
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: "dark",
    themes: {
      dark,
    },
  },
  components: { ...components, VCalendar, VDateInput },
  directives,
});

createApp(App)
  .component("PasswordField", PasswordField)
  .component("TextField", TextField)
  .component("TextAreaField", TextAreaField)
  .component("SelectField", SelectField)
  .component("FileUploadField", FileUploadField)
  .component("ComboBoxField", ComboBoxField)
  .component("AutoCompleteField", AutoCompleteField)
  .component("CurrencyTextField", CurrencyTextField)
  .use(AppInsightsPlugin, {
    instrumentationKey: instrumentationKey,
    appName: "Vue-Teammember-App",
    router,
  } as AppInsightsPluginOptions)
  .use(store)
  .use(router)
  .use(vuetify)
  .provide("setPushToken", () => {
    if (messaging && localStorage.idToken) {
      messaging
        .getToken({ vapidKey: firebaseVapidKey })
        .then((currentToken: any) => {
          if (currentToken) {
            if (localStorage.firebaseToken !== currentToken) {
              UserService.sendPushToken(currentToken);
              localStorage.firebaseToken = currentToken;
            }
          } else {
            // Show permission request UI
            console.log("No registration token available. Request permission to generate one.");
            // ...
          }
        })
        .catch((err: any) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
      messaging.onMessage((payload: any) => {
        console.log("Message received. ", payload);
        store.commit("UPDATE_NOTIFICATION_LIST");
      });
    }
  })
  .mount("#project-portal-app");
