<template>
  <div class="profile-wizard">
    <v-stepper ref="mainRef" v-model="currentStep">
      <v-stepper-header>
        <template v-for="(n, index) in steps" :key="`${n.step}-step`">
          <v-stepper-item :complete="currentStep > n.step" :step="n.step" :value="n.step">
            <template v-slot:title>
              {{ n.title }}
            </template>
            <template v-slot:subtitle>
              <p class="mb-0"><v-icon class="mr-1">mdi-timer</v-icon>{{ n.description }}</p>
            </template>
          </v-stepper-item>
          <div class="step-divider" v-if="index != steps.length - 1"></div>
        </template>
        <div class="step-progress">
          <v-progress-linear :value="wizardStep" color="#0ECB7C" background-color="#F7FFF7"></v-progress-linear>
          <span>Completion: {{ wizardStep }} %</span>
        </div>
      </v-stepper-header>
      <v-stepper-window>
        <v-stepper-window-item :value="1">
          <PersonalDetail
            v-if="currentStep == 1"
            :loading="submitting"
            @submitPersonalDetail="submitPersonalDetail"
            @scrollToElement="scrollToElement"
            @getEducations="getEducations"
            :personalDetailModel="personalDetailModel"
            :isAfterSignUp="true"
            :educations="educations"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="2">
          <ExperienceDetail
            @scrollToElement="scrollToElement"
            :formLoading="submitting"
            :professionalDetailModel="professionalDetailModel"
            :details="details"
            :loading="submitting"
            :teamMember="teamMemberModel"
            :sendReqeustLoading="sendReqeustLoading"
            :isAfterSignUp="true"
            @submitExperienceDetail="submitExperienceDetail"
            @submitExperienceCareerObjectiveDetail="submitExperienceCareerObjectiveDetail"
            @RemoveCareerObjective="RemoveCareerObjective"
            @sendJobRequest="sendJobRequest"
            @goBack="prevStep"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="3">
          <Skills
            v-if="stepMounted.skill"
            @scrollToElement="scrollToElement"
            :formLoading="submitting"
            :details="details"
            :loading="submitting"
            :teamMember="teamMemberModel"
            :sendReqeustLoading="sendReqeustLoading"
            :isAfterSignUp="true"
            :languages="languages"
            :skills="skills"
            :teammemberSkills="teammemberSkills"
            :uploadedCertificate="uploadedCertificate"
            :showEditSkillModal="showEditSkillModal"
            :skillLoading="skillLoading"
            :certificates="certificates"
            @submitSkillsDetail="submitSkillsDetail"
            @goBack="prevStep"
            @showLimitationMessage="showLimitationMessage"
            @skillDownload="skillDownload"
            @skillCertificateChanged="skillCertificateChanged"
            @saveSkill="saveSkill"
            @remove="remove"
            @showEditSkill="showEditSkill"
            @sendAddRequest="sendSkillAddRequest"
            @closeEditModal="closeEditModal"
            @getCertificates="getCertificates"
            @setLanguagesState="setLanguagesState"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="4">
          <CareerHistory
            v-if="stepMounted.career"
            @goNext="nextStep"
            @scrollToElement="scrollToElement"
            @sendJobRequest="sendJobRequest"
            @goBack="prevStep"
            @getCareerHistories="getCareerHistories"
            @setCareerHistoryDescriptionState="setCareerHistoryDescriptionState"
            @updateJobTitle="updateJobTitle"
            @updateSkill="updateSkillList"
            :formLoading="submitting"
            :isAfterSignUp="true"
            :skills="skills"
            :careerHistories="careerHistories"
            :careerHistoryDescription="careerHistoryDescription"
            :teammemberSkills="teammemberSkills"
            :submitting="submitting"
            :details="details"
          />
        </v-stepper-window-item>
        <v-stepper-window-item :value="5">
          <ProfilePhoto
            :loading="submitting"
            @scrollToElement="scrollToElement"
            @submitForm="submitPersonalDetail"
            @goBack="prevStep"
            :teammemberModel="personalDetailModel"
            :isAfterSignUp="true"
          />
        </v-stepper-window-item>
      </v-stepper-window>
      <v-dialog v-if="showConfirm" v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
        <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
      </v-dialog>
      <v-dialog v-if="showJobRequestConfirm" v-model="showJobRequestConfirm" max-width="500" @keydown.esc="showJobRequestConfirm = false">
        <confirm-dialog
          :loading="sendReqeustLoading"
          :message="confirmModel"
          showInput
          inputLable="Job Title"
          @confirm="confirmSendJobRequest"
          @cancel="cancelSendRequest"
        ></confirm-dialog>
      </v-dialog>
      <v-dialog v-if="showSkillRequestConfirm" v-model="showSkillRequestConfirm" max-width="500" @keydown.esc="showSkillRequestConfirm = false">
        <confirm-dialog
          :loading="sendReqeustLoading"
          :message="confirmModel"
          showInput
          inputLable="Skill"
          @confirm="sendAddSkillRequest"
          @cancel="cancelSendRequest"
        ></confirm-dialog>
      </v-dialog>
    </v-stepper>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import UserInfo from "shared-components/src/models/UserInfo";
import { mapMutations, mapGetters } from "vuex";
import { SHOW_LOADING, HIDDEN_LOADING } from "@/store/types";
import { Certificate, Education, LanguageSkill, ProfessionalDetail, Teammember, TeammemberSkill } from "shared-components/src/models/Teammember";
import CareerHistoryService from "shared-components/src/services/CareerHistoryService";
import CoreSkillService from "@/services/CoreSkillService";
import TeamMemberService from "@/services/TeamMemberService";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import CoreSkill from "shared-components/src/models/CoreSkill";
import { ProjectMessages } from "shared-components/src/definitions/constants";
import PersonalDetail from "shared-components/src/components/Profile/PersonalDetail.vue";
import Skills from "./completeProfile/Skills.vue";
import CareerHistory from "shared-components/src/components/Profile/careerHistory/CareerHistory.vue";
import ProfilePhoto from "./completeProfile/ProfilePhoto.vue";
import { Profile } from "shared-components/src/definitions/config";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import DetailsService from "@/services/DetailsService";
import ExperienceDetail from "shared-components/src/components/Profile/ExperienceDetail.vue";
import Details from "shared-components/src/models/Details";
import CareerHistories from "shared-components/src/models/CareerHistories";
import store from "@/store";

export default defineComponent({
  components: {
    ConfirmDialog,
    PersonalDetail,
    ExperienceDetail,
    CareerHistory,
    ProfilePhoto,
    Skills,
  },
  data() {
    return {
      showSkillRequestConfirm: false,
      showJobRequestConfirm: false,
      uploadedCertificate: "",
      careerHistorySummary: "",
      teamMemberModel: null as Teammember | null,
      teammemberSkills: [] as TeammemberSkill[],
      skills: [] as CoreSkill[],
      selectedSkill: {} as TeammemberSkill,
      details: [] as Details[],
      personalDetailModel: {} as PersonalDetailModel,
      showEditSkillModal: false,
      skillLoading: false,
      careerHistoryDescription: "",
      selectedJob: "",
      professionalDetailModel: {} as ProfessionalDetail | null,
      careerHistories: [] as CareerHistories[],
      languages: [] as LanguageSkill[] | null,
      certificates: [] as Certificate[],
      educations: [] as Education[],
      showSaveForLaterConfirm: false,
      savingLoading: false,
      showConfirm: false,
      submitting: false,
      selectedSkillTitle: "",
      isFirstTimeLoad: true,
      sendReqeustLoading: false,
      confirmModel: {
        title: "",
        text: "",
      },
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      currentStep: 1,
      steps: [
        { step: 1, value: "personalDetails", title: "Personal Details", description: "1 MIN" },
        { step: 2, value: "professionalDetails", title: "Professional Details", description: "5 MIN" },
        { step: 3, value: "skills", title: "Skills", description: "5 MIN" },
        { step: 4, value: "careerHistory", title: "Career History", description: "2 MIN" },
        { step: 5, value: "profile", title: "Profile setup", description: "1 MIN" },
      ],
      stepMounted: {
        career: false,
        education: false,
        skill: false,
      },
    };
  },
  created() {
    this.SHOW_LOADING();
  },
  async mounted() {
    await this.loadDetailsList();
    await this.fetchTeammemberInfo();
    await this.loadSkillsList();
    this.isFirstTimeLoad = false;
    this.setMountedValues();
    this.HIDDEN_LOADING();
  },
  methods: {
    setCareerHistoryDescriptionState(careerHistoryDescription: any) {
      if (this.teamMemberModel) {
        this.teamMemberModel.CareerHistoryDescription = careerHistoryDescription;
        this.setTeamMemberInfo(this.teamMemberModel);
      }
    },
    async getCertificates() {
      this.SHOW_LOADING();
      var result = await TeamMemberService.getCertificates();
      if (result) {
        this.certificates = result;
      }
      this.HIDDEN_LOADING();
    },
    async getEducations() {
      this.SHOW_LOADING();
      var result = await TeamMemberService.getEducations();
      if (result) {
        this.educations = result;
      }
      this.HIDDEN_LOADING();
    },
    async getCareerHistories() {
      this.SHOW_LOADING();
      var result = await CareerHistoryService.getCareerHistories();
      if (result) {
        this.careerHistories = result;
      }
      this.HIDDEN_LOADING();
    },
    async sendSkillAddRequest(skillName: any) {
      this.confirmModel.title = `Sending request to add new skill`;
      this.confirmModel.text = `Are you sure you want to send request to admin to add this?`;
      this.showSkillRequestConfirm = true;
      this.selectedSkillTitle = skillName;
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails();
    },
    async RemoveCareerObjective(event: { id: any; callbackMethod: any }) {
      this.submitting = true;
      var result = await TeamMemberService.removeExperienceCareerObjectiveDetail(event.id);
      if (result) {
        if (this.teamMemberModel && this.professionalDetailModel) {
          this.professionalDetailModel.CareerObjectives.splice(
            this.professionalDetailModel.CareerObjectives.findIndex((item) => item.Id == event.id),
            1
          );
          this.teamMemberModel.ProfessionalDetail = this.professionalDetailModel;
          event.callbackMethod(result);
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        store.dispatch("showSuccessMessage", ProjectMessages.succesfullyRemoveExperienceCareerObjectiveDetail);
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.errorRemoveExperienceCareerObjectiveDetail);
      }
      this.submitting = false;
    },
    async submitExperienceCareerObjectiveDetail(event: { model: any; callbackMethod: any }) {
      this.submitting = true;
      var result = await TeamMemberService.saveExperienceCareerObjectiveDetail(event.model);
      if (result != null && result.Id) {
        if (this.teamMemberModel) {
          if (!this.professionalDetailModel) {
            this.professionalDetailModel = {} as ProfessionalDetail;
          }
          if (!this.professionalDetailModel.CareerObjectives) {
            this.professionalDetailModel.CareerObjectives = [];
          }
          let existingItemIndex = this.professionalDetailModel.CareerObjectives.findIndex((item) => item.Id == (result as any).Id);
          if (existingItemIndex == -1) {
            this.professionalDetailModel.CareerObjectives.push(result);
          } else {
            this.professionalDetailModel.CareerObjectives[existingItemIndex] = result;
          }
          this.teamMemberModel.ProfessionalDetail = this.professionalDetailModel;
          event.callbackMethod(result);
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceCareerObjectiveDetail);
      } else if (result == null) {
        store.dispatch("showErrorMessage", ProjectMessages.errorAddExperienceCareerObjectiveDetail);
      } else {
        store.dispatch("showErrorMessage", result);
      }
      this.submitting = false;
    },
    updateJobTitle(item: Details) {
      var detailList = [...this.details];
      detailList.push(item);
      this.details = detailList;
    },
    async submitExperienceDetail(model: any) {
      this.submitting = true;
      if (model.tm.Job) {
        var jobtitle = this.details.find(
          (c) => c.Type == "JobTitle" && (c.id === model.tm.Job || c.Name.trim().toLowerCase() === model.tm.Job.trim().toLowerCase())
        );
        if (jobtitle) {
          model.tm.Job = jobtitle.id;
        }
      }
      var result = await TeamMemberService.saveExperienceDetail(model.tm);
      if (result) {
        if (this.teamMemberModel) {
          this.teamMemberModel.ProfessionalDetail = model.tm;
          this.teamMemberModel.SpecificRoles = model.tm.JobRole;
          if (!jobtitle) {
            this.updateJobTitle({ id: result.Job, Name: model.tm.Job, RoleId: model.tm.JobRole, Type: "JobTitle" } as Details);
          }
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (model.step) {
          this.nextStep(model.step);
        } else {
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceDetail);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.errorAddExperienceDetail);
      }
      this.submitting = false;
    },
    async submitSkillsDetail(model: any) {
      this.submitting = true;
      if (model.step) {
        this.nextStep(model.step);
      } else {
        store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddExperienceDetail);
      }
      this.submitting = false;
    },
    async submitPersonalDetail(model: any) {
      this.submitting = true;
      var result = await TeamMemberService.savePersonalDetail(model.tm);
      if (result && result.Id) {
        this.personalDetailModel = result;
        if (this.teamMemberModel) {
          this.teamMemberModel.BirthDate = result.BirthDate;
          this.teamMemberModel.Country = result.Country;
          this.teamMemberModel.City = result.City;
          this.teamMemberModel.CV = result.CV;
          this.teamMemberModel.Description = result.Description;
          this.teamMemberModel.Email = result.Email;
          this.teamMemberModel.FirstName = result.FirstName;
          this.teamMemberModel.GitHub = result.GitHub;
          this.teamMemberModel.LastName = result.LastName;
          this.teamMemberModel.LinkedIn = result.LinkedIn;
          this.teamMemberModel.MaritalStatus = result.MaritalStatus;
          this.teamMemberModel.MilitaryServiceSituation = result.MilitaryServiceSituation;
          this.teamMemberModel.Mobile = result.Mobile;
          this.teamMemberModel.Nationality = result.Nationality;
          this.teamMemberModel.NickName = result.NickName;
          this.teamMemberModel.Phone = result.Phone;
          this.teamMemberModel.PhotoUrl = result.PhotoUrl;
          this.teamMemberModel.SecondaryEmail = result.SecondaryEmail;
          this.teamMemberModel.StillStudying = result.StillStudying;
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (model.isLastStep) {
          if (!model.isForLator) {
            this.isProfileCompleted();
          }
          else{
            store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddProfileDetails);

          }
        } else if (model.step) {
          this.nextStep(model.step);
        } else {
          
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyAddPersonalDetails);
        }
      } else {
        store.dispatch("showErrorMessage", ProjectMessages.errorAddPersonalDetails);
      }
      this.submitting = false;
    },

    async skillCertificateChanged(skill: any) {
      if (this.teamMemberModel) {
        this.uploadedCertificate = await TeamMemberService.uploadSkillCv(skill.skillId, skill.file);
      }
    },
    async skillDownload(certificateUrl: any) {
      if (this.teamMemberModel) {
        const downloadLink = await TeamMemberService.getCertificateDownloadLink(certificateUrl || "");
        window.open(downloadLink, "_blank");
      }
    },
    showLimitationMessage(text: any) {
      store.dispatch("showSuccessMessage", text);
    },
    async saveTeammember(data: any) {
      this.submitting = true;
      var result = await TeamMemberService.create(data.tm);
      if (result) {
        this.teamMemberModel = data.tm;
        if (this.teamMemberModel) {
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        if (data.step) {
          this.nextStep(data.step);
        }
        if (data.isLastStep) {
          this.isProfileCompleted();
        }
      } else {
        store.dispatch("showErrorMessage", "Couldn't save Professional details!");
      }
      this.submitting = false;
    },
    async fetchTeammemberInfo() {
      var step = 0;
      try {
        if (!this.userInfo || !this.userInfo.id) {
          throw Error("Please login!");
        } else {
          this.teamMemberModel = await TeamMemberService.GetTeammemberWithProfessional();
        }
      } catch (teammemberError) {
        store.dispatch("showErrorMessage", "There is some error to get your team member inforamtion!");
      }
      if (this.teamMemberModel) {
        if (!this.teamMemberModel.FirstName) {
          this.teamMemberModel.FirstName = this.userInfo.name;
        }
        if (!this.teamMemberModel.LastName) {
          this.teamMemberModel.LastName = this.userInfo.surname;
        }
        if (!this.teamMemberModel.Phone) {
          this.teamMemberModel.Phone = this.userInfo.phoneNumber;
        }
        if (!this.teamMemberModel.Email) {
          this.teamMemberModel.Email = this.userInfo.userName || "";
        }
        if (!this.teamMemberModel.MemberSince) {
          this.teamMemberModel.MemberSince = this.userInfo.creationDate;
        }
        this.teammemberSkills = this.teamMemberModel.Skills ? this.teamMemberModel.Skills : [];
        if (!this.teamMemberModel.SecondaryEmail) {
          this.teamMemberModel.SecondaryEmail = this.teamMemberModel.Email;
        }
        this.personalDetailModel = {
          Id: this.teamMemberModel.Id,
          BirthDate: this.teamMemberModel.BirthDate,
          Country: this.teamMemberModel.Country,
          City: this.teamMemberModel.City,
          CV: this.teamMemberModel.CV,
          Description: this.teamMemberModel.Description,
          Email: this.teamMemberModel.Email,
          FirstName: this.teamMemberModel.FirstName,
          GitHub: this.teamMemberModel.GitHub,
          LastName: this.teamMemberModel.LastName,
          LinkedIn: this.teamMemberModel.LinkedIn,
          MaritalStatus: this.teamMemberModel.MaritalStatus,
          MilitaryServiceSituation: this.teamMemberModel.MilitaryServiceSituation,
          Mobile: this.teamMemberModel.Mobile,
          Nationality: this.teamMemberModel.Nationality,
          NickName: this.teamMemberModel.NickName,
          Phone: this.teamMemberModel.Phone,
          PhotoUrl: this.teamMemberModel.PhotoUrl,
          SecondaryEmail: this.teamMemberModel.SecondaryEmail,
          StillStudying: this.teamMemberModel.StillStudying,
        };
        this.careerHistoryDescription = this.teamMemberModel.CareerHistoryDescription;
        this.languages = this.teamMemberModel.LanguageSkills;
        if (this.teamMemberModel.SpecificRoles && this.teamMemberModel.ProfessionalDetail) {
          this.teamMemberModel.ProfessionalDetail.JobRole = this.teamMemberModel.SpecificRoles;
        }
        this.professionalDetailModel = this.teamMemberModel.ProfessionalDetail;
        if (!this.hasPersonalDetail(this.teamMemberModel)) {
          step = 0;
        } else if (!this.hasProfessionalDetails(this.teamMemberModel)) {
          step = 1;
        } else if (!this.hasCareerHistory(this.teamMemberModel)) {
          step = 3;
        } else if (!this.hasProfileDetail(this.teamMemberModel)) {
          step = 4;
        }
        if (step != 0) {
          this.nextStep(step);
        }
      }
    },
    hasPersonalDetail(tm: Teammember) {
      return (
        tm.FirstName && tm.LastName && tm.BirthDate && tm.Phone && tm.Email && tm.Nationality && tm.Country && tm.City && tm.StillStudying != null
      );
    },
    hasProfessionalDetails(tm: Teammember) {
      return (
        tm.ProfessionalDetail &&
        tm.ProfessionalDetail.Job &&
        tm.SpecificRoles &&
        tm.SpecificRoles.length > 0 &&
        tm.ProfessionalDetail.CareerObjectives &&
        tm.ProfessionalDetail.CareerObjectives.length > 0 &&
        tm.ProfessionalDetail.ExperienceYear &&
        tm.ProfessionalDetail.ExperienceYear != "0" &&
        tm.ProfessionalDetail.Personality &&
        tm.ProfessionalDetail.Personality.length >= 250
      );
    },
    hasCareerHistory(tm: any) {
      return tm.CareerHistoryDescription && tm.CareerHistoryDescription.length >= 250 ? true : false;
    },
    hasProfileDetail(tm: any) {
      return tm.Description && tm.Description.lengh >= 250 && tm.PhotoUrl && tm.CV;
    },
    isProfileCompleted() {
      store.dispatch("showSuccessMessage", "Congratulations! You completed your profile successfuly. thanks for joining our team!");
      this.$router.push("/teamMember/dashboard");
    },
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    async remove(item: TeammemberSkill) {
      var findSkill = this.skills.find((c) => c.id === item.SkillId);
      if (findSkill) {
        this.confirmModel.title = `Remove Skill(${findSkill.Text})`;
        this.confirmModel.text = "Are you sure?";
        this.selectedSkill = item;
        this.showConfirm = true;
      }
    },
    async sendAddSkillRequest(skill: any) {
      this.sendReqeustLoading = true;
      var skillTilteExist = this.skills.some((item) => item.Text && item.Text.toLowerCase().trim() === skill.toLowerCase().trim());
      if (skillTilteExist) {
        store.dispatch("showErrorMessage", ProjectMessages.DuplicateSkillTitle);
      }
      this.sendReqeustLoading = false;
      this.showSkillRequestConfirm = false;
    },
    cancelSendRequest() {
      this.showJobRequestConfirm = false;
      this.showSkillRequestConfirm = false;
      this.selectedSkillTitle = "";
      this.selectedJob = "";
    },
    setLanguagesState(items: any) {
      if (this.teamMemberModel) {
        this.teamMemberModel.LanguageSkills = items;
        this.setTeamMemberInfo(this.teamMemberModel);
      }
    },
    sendJobRequest(job: any) {
      this.confirmModel.title = "Sending request to add a Job Title";
      this.confirmModel.text = "Are you sure you want to send request to admin to add This?";
      this.showJobRequestConfirm = true;
    },
    async confirmSendJobRequest(job: any) {
      var jobTilteExist = this.details.some(
        (item) => item.Name && job && item.Type == "JobTitle" && item.Name.toLowerCase().trim() === job.toLowerCase().trim()
      );
      if (jobTilteExist) {
        store.dispatch("showErrorMessage", ProjectMessages.DuplicateJobTitle);
      }
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      this.skillLoading = true;
      if (this.selectedSkill) {
        const idx = this.teammemberSkills.findIndex((x) => x.SkillId === (this.selectedSkill ? this.selectedSkill.SkillId : ""));
        const result = await TeamMemberService.removeSkill(this.selectedSkill.SkillId);
        if (result) {
          this.teammemberSkills = result;
          if (this.teamMemberModel) {
            this.teamMemberModel.Skills = result;
            this.setTeamMemberInfo(this.teamMemberModel);
          }
          store.dispatch("showSuccessMessage", ProjectMessages.succesfullyRemoveSkill);
        } else {
          store.dispatch("showErrorMessage", ProjectMessages.errorRemoveSkill);
        }
      }
      this.skillLoading = false;
    },
    cancelRemove(): void {
      this.showConfirm = false;
      this.selectedSkill = {} as TeammemberSkill;
      (this.selectedJob = ""), (this.showJobRequestConfirm = false);
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    updateSkillList(newVal: any) {
      var skillDatas = [...this.skills];
      var mappedSkills = newVal.newSkills.map((c: any) => {
        return { Text: c.Text, id: c.id };
      });
      skillDatas.push(...mappedSkills);
      this.skills = skillDatas;
    },
    async saveSkill(skillModel: TeammemberSkill) {
      this.skillLoading = true;
      var findSkill = this.skills.find((c) => c.id === skillModel.SkillId || c.Text.trim().toLowerCase() === skillModel.SkillId.trim().toLowerCase());
      if (findSkill) {
        skillModel.SkillId = skillModel.SkillId;
      }
      var result = await TeamMemberService.updateSkill(skillModel);
      if (result && result.length > 0) {
        if (this.teamMemberModel) {
          if (!findSkill) {
            var skillData = [...this.skills];
            var mappedSkills = this.skills.map((c) => c.id);
            var savedSkill = result.find((c) => !mappedSkills.includes(c.SkillId));
            if (savedSkill) {
              skillData.push({ id: savedSkill.SkillId, Text: skillModel.SkillId } as CoreSkill);
              this.skills = skillData;
            }
          }
          this.teamMemberModel.Skills = result;
          this.setTeamMemberInfo(this.teamMemberModel);
        }
        this.teammemberSkills = result;
      }
      this.skillLoading = false;
      this.closeEditModal();
    },
    async sendAddRequest() {
      this.sendReqeustLoading = true;
      this.sendReqeustLoading = false;
      this.showSkillRequestConfirm = false;
    },
    closeEditModal() {
      this.showEditSkillModal = false;
    },
    showEditSkill() {
      this.showEditSkillModal = true;
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    async scrollToElement(el: any) {
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    },
    nextStep(n: any) {
      if (n === this.steps.length) {
        this.currentStep = 1;
      } else {
        this.currentStep = n + 1;
      }
      this.setMountedValues();
      var el = document.querySelector("#main-body");
      if (el) {
        el.scrollIntoView(true);
      }
    },
    prevStep(stepNumber: any) {
      stepNumber = stepNumber - 1;
      this.nextStep(stepNumber);
    },
    setMountedValues() {
      switch (this.currentStep) {
        case 1:
          this.stepMounted.education = true;
        case 3:
          this.stepMounted.skill = true;
        case 4:
          this.stepMounted.career = true;
      }
    },
  },
  computed: {
    ...mapGetters(["teamMemberInfo"]),
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    wizardStep(): number {
      return (100 / this.steps.length) * this.currentStep;
    },
  },
});
</script>
<style lang="scss">
@import "node_modules/shared-components/assets/style/color.scss";

.v-application--wrap {
  overflow-y: scroll;
}

.profile-wizard {
  .wizard-action {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;

    button:last-child {
      margin-left: 10px;
    }
  }

  .v-card-actions {
    height: auto !important;
  }

  .v-stepper {
    background: transparent;

    .v-stepper-item__content {
      padding: 16px 7px 16px 7px;
    }

    .v-stepper-header {
      justify-content: unset !important;
      align-items: center !important;
      height: auto;

      button.v-stepper-item:not(.v-stepper-item--selected) {
        .v-stepper-item__avatar {
          color: $c_white;
          background-color: $c_black !important;
        }

        .v-stepper-item__content {
          color: $c_white !important;

          .small {
            color: $c_white !important;
          }
        }
      }

      .v-stepper-item--complete {
        .v-stepper-item__avatar {
          background-color: $c_battleship_grey1 !important;
        }
      }

      .v-stepper-item--selected {
        .v-stepper-item__avatar {
          background-color: $c_emerald !important;
          color: $c_emerald !important;
        }
      }

      .v-stepper-item {
        padding: 10px;
        padding-bottom: 0px;
      }

      button.v-stepper-item:not(.v-stepper-item--selected) {
        background-color: #b3b3b38a;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        color: $c_dim_gray;
      }

      .step-divider {
        width: 15px;
        height: 15px;
        border: solid $c_white;
        border-width: 0 3px 3px 0;
        display: block;
        padding: 6px;
        margin-right: 10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      .step-progress {
        display: flex;
        gap: 50px;
        margin: 10px 15px;
        width: 100%;
        align-items: center;

        span {
          white-space: nowrap;
        }
      }
    }

    @media screen and (max-width: 1212px) {
      .v-stepper-header {
        justify-content: center !important;

        .v-stepper__step--inactive,
        .v-stepper-item--complete {
          background-color: transparent;
        }

        .v-stepper-item__content {
          display: none;
        }

        .v-stepper-item {
          padding: 0 10px;
          padding-top: 0 !important;
        }

        .step-divider {
          width: 10px;
          height: 10px;
          padding: 4px;
        }

        .step-progress {
          margin: 15px;

          span {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 480px) {
      .wizard-action {
        flex-direction: column;

        button:last-child {
          margin-left: 0 !important;
          margin-top: 10px !important;
        }
      }

      .v-stepper-header {
        .v-stepper-header {
          display: none;
        }

        .step-divider {
          display: none;
        }
      }
    }
  }
}
</style>
