<template>
  <v-list class="pt-0 pl-0 main-menu-items" open-strategy="single" slim>
    <template v-if="!!isAuthenticated && profileCompleted">
      <v-list-item v-if="commitments && commitments.length > 0" to="/team-member/dashboard" prepend-icon="mdi-view-dashboard" title='Dashboard'/>
      <v-list-item to="/team-member/profile" prepend-icon="mdi-account" title='Profile' class="profile-item"/>
      <v-list-group value="traning">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-book" title="My Training"/>
        </template>
        <v-list-item to="/team-member/training/roadmaps" prepend-icon="mdi-school" title="My Roadmaps"/>
        <v-list-item to="/team-member/training/courses" prepend-icon="mdi-book-outline" title="Courses"/>
        <v-list-item to="/team-member/training/assessments" prepend-icon="mdi-school-outline" title="Assessments"/>
      </v-list-group>
      <v-list-item v-if="commitments && commitments.length > 0" to="/team-member/timesheet" prepend-icon="mdi-history" title="Timesheet"/>
        <v-list-group >
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="mdi-cash" title="Billing"/>
        </template>
        <v-list-item v-if="showPayrunPage" to="/team-member/pay-runs" prepend-icon="mdi-account-cash" title="Pay Runs"/>
        <v-list-item v-if="showPayrunPage" to="/team-member/loans" prepend-icon="mdi-cash-multiple" title="Loans"/>
        <v-list-item to="/team-member/paymentMethods" prepend-icon="mdi-cash-refund" title="Payment Method"/>
      </v-list-group>
      <v-list-item v-if="hasActiveCommitment" to="/team-member/repositories" prepend-icon="mdi-source-repository" title="Repositories"/>
      <v-list-item v-if="hasActiveCommitment" to="/team-member/commitments" prepend-icon="mdi-handshake" title="My Commitments"/>
      <v-list-item v-if="!!isLinemanager" to="/team-member/access/list" prepend-icon="mdi-server-security" title="Accesses"/>
      <v-list-item v-if="!!isLinemanager" to="/team-member/reports/timesheet-report" prepend-icon="mdi-chart-arc" title="Timesheets Report"/>
      <v-list-item v-if="!!isLinemanager" to="/team-member/weekly-timesheet-audit" prepend-icon="mdi-chart-bar" title="Weekly Timesheet Audit"/>
      <v-list-item v-if="!!isLinemanager" to="/team-member/authors" prepend-icon="mdi-git" title="Authors Management"/>
    </template>
    <v-divider v-show="appMenu.length > 0" />
    <v-list-item v-for="(item, index) in appMenu" :key="index" :to="item.to" :prepend-icon="item.icon" :title="item.text"/>
  </v-list>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import appData from "@/appData";
import store from "../store";

export default defineComponent({
  props: {
    profileCompleted: {
      type: Boolean,
    },
    isAuthenticated: {
      type: Boolean,
    },
    isLinemanager: {
      type: Boolean,
    },
    avatar: {
      type: Object as () => any,
      required: false,
    },
    commitments: {
      type: Array as () => any,
      required: false,
    },
    hasActiveCommitment: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      teamMemberInfo: store.state.teamMemberInfo,
      appMenu: appData.appMenu,
    };
  },
  computed: {
    showPayrunPage(): boolean {
      const theDate = new Date();
      theDate.setMonth(theDate.getMonth() - 2);
      return this.commitments && this.commitments.length > 0 && this.commitments.some((c: any) => c.IsActive || (c.EndDate && c.EndDate >= theDate));
    },
  },
});
</script>

<style scoped lang="scss">

.main-menu-items {
  background: none;
  margin-top: 12px;
}



.trainig-subtitle {
  .v-list-item {
    padding-left: 0px;
  }
}

.roadmap {
  padding-left: 25px !important;
}
</style>
